<template>
  <div class="gr-main-container">
    <section class="mentions">
      <div class="uk-container">
        <h2>Données Personnelles</h2>
        <!--<ul class="uk-subnav uk-subnav-pill" uk-switcher>
          <li><a href="#">Quelles données recuillons-nous&nbsp;?</a></li>
          <li><a href="#">Pourquoi et comment sont traitées vos données&nbsp;?</a></li>
          <li><a href="#">Quels sont les destinataires de vos données&nbsp;?</a></li>
          <li><a href="#">A propos de vos droits</a></li>
        </ul>-->
        <ul uk-tab>
          <li><a href="#">Quelles données recueillons-nous&nbsp;?</a></li>
          <li><a href="#">Pourquoi et comment sont traitées vos données&nbsp;?</a></li>
          <li><a href="#">Quels sont les destinataires de vos données&nbsp;?</a></li>
          <li><a href="#">A propos de vos droits</a></li>
        </ul>
        <ul class="uk-switcher uk-margin gr-mentions gr-background-grey">
            <li>
              <p>Les différents Traitements de Données à caractère personnel sont décrits aux Conditions Générales de vente, Conditions Particulières et Spécifiques des offres concernées. Les modalités du régime applicable entre SFR et ses Clients sont définies dans les Conditions Générales des Services Grandraid.sfr.re .&nbsp;</p>
              <h3>Définitions</h3>
              <p>Les termes&nbsp; figurant à l’ « article 4 Définitions » du Règlement (UE) 2016/679 Général sur la Protection des Données ont la même définition dans la présente politique.</p>
              <p><span>Client</span>: désigne une personne physique représentant une entreprise qui a souscrit un ou plusieurs contrats auprès de SFR.&nbsp;</p>
              <p><span>Client potentiel</span>&nbsp;: désigne une personne physique représentant une entreprise qui souhaite potentiellement souscrire à un ou plusieurs contrats et/ou qui visite le site internet de SFR.</p>
              <p><span>Utilisateur</span>: désigne une personne physique, non titulaire du contrat, jouissant de l’utilisation du service commandé par le Client pour les besoins du Client.&nbsp;</p>
              <p><span>Interlocuteur</span>: désigne une personne physique désignée par le Client ou Client potentiel.</p>
              <h3>Une collecte des Données à caractère personnel transparente et loyale</h3>
              <p>Selon le principe de minimisation, SFR ne traite que les Données à caractère personnel nécessaires au regard des finalités pour lesquelles elles sont traitées et après vous en avoir informé dans les conditions prévues par la Règlementation.</p>
              <p>Les différentes catégories de Données à caractère personnel collectées lors votre souscription et dans le cadre de votre utilisation de nos services ou applications sont par exemple les suivantes :&nbsp;</p>
              <ul>
                <li>Données d’identification du Client, Interlocuteur et Utilisateurs (notamment nom, prénom, numéro de client, adresse postale ou électronique) ;</li>
                <li>Données d’usage (notamment pages web visitées) ;</li>
                <li>Données de connexion (notamment adresse IP de votre ordinateur, logs de connexion et d’usage) ;&nbsp;</li>
                <li>Données financières (notamment moyens et historiques de paiement) ;</li>
              </ul>
              <p>La présente politique de protection des Données à caractère personnel concerne uniquement vos Données à caractère personnel&nbsp; dont SFR est Destinataire en tant qu’opérateur pour les besoins de la fourniture des services, ainsi que les données issues de son activité d’opérateur. Sur lesdites données, SFR agit en qualité de Responsable de Traitement, en étant autonome quant aux finalités et aux moyens mis en œuvre sur les Traitements qu’il est amené à effectuer dans le cadre de la commercialisation et de la bonne exécution des services.</p>
              <h4>Concernant le contenu des communications électroniques :</h4>
              <p>Pour rappel, SFR est soumis aux principes de secret des correspondances, de neutralité, et de sécurité des communications prévus aux articles L34-1 et L33-1 du Code des postes et des télécommunications. Le contenu des messages transmis via un service de communications électroniques est de la responsabilité de la personne dont ils émanent. A ce titre, les Données à caractère personnel contenues dans les messages transmis par le Client via les services fournis ne sont pas traitées par SFR.</p>
              <h4>Dans le cadre du site internet SFR :&nbsp;</h4>
              <p>Pour les Traitements de Données à caractère personnel que vous nous communiquez et qui sont collectées notamment du fait des formulaires qui sont présents sur ce site (inscription à la newsletter et les formulaires de contact), SFR est le Responsable de Traitement de ces Données à caractère personnel. SFR s’engage au respect des obligations incombant au Responsable de Traitement.</p>
              <p>Vous êtes informé(e) que les Données à caractère personnel que vous communiquez notamment du fait des formulaires qui sont présents sur ce site (inscription à la newsletter et dans les formulaires de contact) permettent de répondre à vos demandes et sont uniquement destinées à SFR, en tant que Responsable du Traitement, sur la base de son intérêt légitime aux fins de gestion d’une base de contacts et de prospection commerciale.</p>
              <h4>Dans le cadre de la prospection commerciale :</h4>
              <p>Vous êtes informé(e) que les Données à caractère personnel que vous communiquez à SFR et à ses vendeurs, en particulier les données concernant les Interlocuteurs, sont utilisées à des fins de prospection commerciale pour vous proposer des offres répondant à vos besoins ou pour la réalisation d'opérations de fidélisation.&nbsp;</p>
              <p>SFR peut être amenée à communiquer vos Données à caractère personnel notamment à son partenaire INTELCIA (filiale du groupe Altice) pour les opérations de fidélisation et son prestataire "Société de développement des avis de contributeur" (prestataire d’INTELCIA) pour le suivi de votre satisfaction.</p>
              <p>Vous pouvez à tout moment vous opposer à cette utilisation de vos Données à caractère personnel et exercer vos droits.&nbsp;</p>
              <!--<h4>Parametrage du suivi:</h4>
              <iframe class="iframe-matomo" src="https://mgr.sfrhd.net/matomo/index.php?module=CoreAdminHome&action=optOut&language=fr&backgroundColor=&fontColor=&fontSize=12px&fontFamily=SFR"></iframe>
              -->
            </li>
            <li>
              <h3>Des finalités explicites et légitimes</h3>
              <p>Vos Données à caractère personnel sont traitées uniquement sur la base des fondements juridiques prévus par la Réglementation.</p>
              <p>Dans le cadre de l’exécution d’un contrat, par exemple à des fins d'identification du Client, Interlocuteur ou Utilisateur :&nbsp;</p>
              <ul>
                <li>Gestion du compte client (Communications avec le Client et Interlocuteur : assistance technique et commerciale) ;</li>
                <li>Identification et authentification du Client, Interlocuteur ou Utilisateur ;&nbsp;</li>
                <li>Gestion de la facturation;</li>
                <li>Administration et gestion du réseau et des services (Fourniture et fonctionnement des services).</li>
                <li>Acheminement des communications électroniques</li>
                <li></li>
              </ul>
              <p>Sur la base de l’intérêt légitime ou, le cas échéant, sur la base de votre consentement, par exemple :</p>
              <ul>
                <li>Lutte contre la fraude et prévention des impayés ;</li>
                <li>Développement des produits et services de SFR ou de tiers;&nbsp;</li>
                <li>Déploiement, exploitation, sécurisation du réseau;&nbsp;</li>
                <li>Enrichissement et valorisation de la base clients/prospects pour la prospection commerciale;&nbsp;</li>
              </ul>
              <p>Pour assurer le respect de nos obligations légales et règlementaires telles que définies par la législation en vigueur.</p>
              <h3>Une durée de conservation proportionnée</h3>
              <p>SFR conserve vos Données à caractère personnel uniquement pendant la durée nécessaire à l’accomplissement des finalités mentionnées ci-dessus, dans le respect de la Réglementation.&nbsp;</p>
              <p>Vos données communiquées, utilisées à des fins de prospection commerciale, notamment du fait des formulaires qui sont présents sur ce site, sont conservées pour une durée maximale de trois (3) ans à compter du dernier contact.</p>
              <h3>Des mesures de sécurité adaptées pour protéger vos Données à caractère personnel</h3>
              <p>SFR a défini et mis en place des mesures techniques et organisationnelles permettant de protéger vos Données à caractère personnel de façon appropriée selon leur nature, l’étendue du Traitement et leur accessibilité. Il peut s’agir par exemple de chiffrement des Données à caractère personnel, de gestion de droits d’accès, de sécurisation des flux...</p>
              <p>Le respect de la sécurité et de la protection de vos Données à caractère personnel s’impose à l’ensemble de nos collaborateurs ainsi qu’à nos partenaires et prestataires.</p>
              <p></p>
              <p></p>
              <p></p>
              <p></p>
            </li>
            <li>
              <h3>Les Destinataires de transferts de Données à caractère personnel :&nbsp;</h3>
              <p>Dans le cadre de la bonne exécution des contrats clients, SFR peut être amenée à transférer vos Données à caractère personnel vers différents Destinataires en prévoyant les garanties appropriées. Ces Destinataires se doivent de respecter les obligations liées à la protection des Données à caractère personnel. Lorsqu’ils agissent comme Sous-Traitant, ils peuvent uniquement traiter les Données à caractère personnel dans le cadre des finalités prévues dans les instructions données par SFR.</p>
              <p>Si ces Destinataires sont amenés à traiter ces Données à caractère personnel en dehors de l’Union européenne (UE) et dans un pays n’assurant pas un niveau de protection adéquate au sens de la Règlementation, ils auront alors préalablement signé les « clauses contractuelles types » afin de garantir la mise en œuvre de mesures techniques et organisationnelles appropriées.&nbsp; &nbsp;Si tel est le cas, vous serez informés des dispositions&nbsp; &nbsp;prévues dans les Conditions Particulières et Spécifiques Grandraid.sfr.re des offres concernées ou tout autre document décrivant l’offre grandraid.sfr.re.</p>
              <h4>Lorsque SFR agit en qualité de Responsable de Traitement</h4>
              <p>Les différents Destinataires peuvent être :</p>
              <ul>
                <li>Des filiales du groupe Altice, qui agissent en qualité de Sous-Traitant,</li>
                <li>Des autorités nationales ou tout autre organisme public (comme l’administration fiscale),&nbsp;</li>
                <li>Des fournisseurs,&nbsp; agissant en qualité de Sous-Traitant, basés dans l’UE et en dehors de l’UE (précisés ci-dessous).&nbsp;</li>
              </ul>
              <table class="mentiontable" border="3">
                <tbody>
                  <tr>
                    <td width="33%"><strong>Traitement</strong></td>
                    <td width="33%"><strong>Activités</strong></td>
                    <td width="33%"><strong>Localisation Hors UE</strong></td>
                  </tr>
                  <tr>
                    <td>Gestion des comptes clients</td>
                    <td>Opérations de fidélisation</td>
                    <td>île Maurice</td>
                  </tr>
                  <tr>
                    <td>Gestion des comptes clients</td>
                    <td>Gestion Relation Clients en Front Office et Back Office</td>
                    <td>île Maurice</td>
                  </tr>
                  <tr>
                    <td>Gestion des comptes clients</td>
                    <td>Opérations de pré-recouvrement</td>
                    <td>île Maurice</td>
                  </tr>
                  <tr>
                    <td>Gestion des comptes Clients</td>
                    <td>Gestion du contrôle des premières factures</td>
                    <td>île Maurice</td>
                  </tr>
                  <tr>
                    <td>Gestion des comptes clients</td>
                    <td>Opérations de marketing digital direct</td>
                    <td>île Maurice</td>
                  </tr>
                  <tr>
                    <td>Gestion des comptes potentiels</td>
                    <td>Opérations de marketing digital direct</td>
                    <td>île Maurice</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              <h3>Un exercice effectif de vos droits sur vos Données à caractère personnel</h3>
              <p>En tant que personne physique concernée, vous pouvez demander à tout moment l’accès aux Données à caractère personnel vous concernant, leur rectification, leur effacement (dans la mesure où cela n’empêche pas la bonne exécution du contrat ou le respect des obligations légales de SFR) et la limitation d’un ou plusieurs Traitements particuliers de Données à caractère personnel vous concernant soit en vous adressant à l’interlocuteur désigné à cet effet par votre entreprise qui prendra contact avec SFR, soit en envoyant votre demande directement à SFR à l’adresse suivante :</p>
              <p>SRR - Service&nbsp;Client, DPO - Délégué à la Protection des Données - 21 rue Pierre Aubert CS 62001 - 97743 Saint-Denis&nbsp;Cedex</p>
              <p>Vous disposez par ailleurs du droit de vous opposer à un Traitement de vos Données à caractère personnel et du droit à demander leur portabilité, dans les conditions fixées par la Réglementation.</p>
              <p>Si les réponses apportées ne vous semblent pas satisfaisantes, vous disposez du droit de saisir la CNIL.</p>
              <h3>En savoir plus</h3>
              <p>Pour en savoir plus sur notre Politique de protection des Données à caractère personnel, vous pouvez contacter le Délégué à la Protection des Données à l’adresse suivante:</p>
              <p>SRR - Service Client, DPO - Délégué à la Protection des Données - 21 rue Pierre Aubert CS 62001 - 97743 Saint-Denis Cedex.</p>

            </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'MentionsLegales',
  props: {
    msg: String
  }
}
</script>
