<template>

    <DonneesPersonnelles msg="" />

</template>

<script>
// @ is an alias to /src
import DonneesPersonnelles from '@/components/DonneesPersonnelles.vue'

export default {
  name: 'DonneesPersonnellesView',
  components: {
    DonneesPersonnelles,
  }
}
</script>
