<template>

    <MentionsLegales msg="" />

</template>

<script>
// @ is an alias to /src
import MentionsLegales from '@/components/MentionsLegales.vue'

export default {
  name: 'MentionsLegalesView',
  components: {
    MentionsLegales,
  }
}
</script>
