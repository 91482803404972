import { createRouter, createWebHashHistory } from 'vue-router'
import HomeLive from '../views/HomeLiveView.vue'
import FooterContent from '@/components/Footer.vue'
import HeaderContent from '@/components/Header.vue'
import HeaderEmptyContent from '@/components/HeaderEmpty.vue'
import StickyBottom from '@/components/StickyBottom.vue'

import MentionsLegalesView from '../views/MentionsLegalesView.vue'
import DonneesPersonnellesView from '../views/DonneesPersonnellesView.vue'
import GestionCookiesView from '../views/GestionCookiesView.vue'
import CGLView from '../views/CGL.vue'

const routes = [
  /*{
    path: '/',
    name: 'home',
    component: HomeView
  },*/
  /*{
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import( '../views/AboutView.vue')
  }*/
  {
    path: '/',
    name: 'suivi-live',
    components:{
      default:HomeLive,
      footerlive:FooterContent,
      headerlive:HeaderContent,
      stickybottommobilelive:StickyBottom,
    },
    meta: {
      title: 'Grand Raid Réunion du 17 au 20 octobre 2024 - SFR Grand Raid',
      metaTags: [
        {
          name: 'description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        },
        {
          property: 'og:description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        }
      ]
    }
  }
  ,
  {
    path: '/mentions-legales',
    name: 'mentionslegales',
    components:{
        default:MentionsLegalesView,
        footerlive:FooterContent,
        headerlive:HeaderEmptyContent,
    },
    meta: {
      title: 'Grand Raid Réunion du 17 au 20 octobre 2024 - SFR Grand Raid - Mentions Légales',
      metaTags: [
        {
          name: 'description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        },
        {
          property: 'og:description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        }
      ]
    }
  },
  {
    path: '/donnees-personnelles',
    name: 'donneespersonnelles',
    components:{
      default:DonneesPersonnellesView,
      footerlive:FooterContent,
      headerlive:HeaderEmptyContent,
    },
    meta: {
      title: 'Grand Raid Réunion du 17 au 20 octobre 2024 - SFR Grand Raid - Données Personnelles',
      metaTags: [
        {
          name: 'description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        },
        {
          property: 'og:description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        }
      ]
    }
  },
  {
    path: '/gestion-cookies',
    name: 'gestioncookies',
    components:{
      default:GestionCookiesView,
      footerlive:FooterContent,
      headerlive:HeaderEmptyContent,
    },
    meta: {
      title: 'Grand Raid Réunion du 17 au 20 octobre 2024 - SFR Grand Raid - Gestion des cookies',
      metaTags: [
        {
          name: 'description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        },
        {
          property: 'og:description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        }
      ]
    }
  },
  {
    path: '/conditions-generales-de-location',
    name: 'conditionsgeneraleslocation',
    components:{
      default:CGLView,
      footerlive:FooterContent,
      headerlive:HeaderEmptyContent,
    },
    meta: {
      title: 'Grand Raid Réunion du 17 au 20 octobre 2024 - SFR Grand Raid - Conditions Générale de location',
      metaTags: [
        {
          name: 'description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        },
        {
          property: 'og:description',
          content: 'Diagonale des Fous, Trail de Bourbon, Mascareignes, relais Zembrocal Trail – Louez votre balise GPS Capturs, profitez du Suivi Facebook Messenger, retrouvez l’actualité du Grand Raid !'
        }
      ]
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
