<template>

    <HomeLive msg="" />

</template>

<script>
// @ is an alias to /src
import HomeLive from '@/components/HomeLive.vue'

export default {
  name: 'HomeLiveView',
  components: {
    HomeLive,
  }
}
</script>
