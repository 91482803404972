<template>

    <GestionCookies msg="" />

</template>

<script>
// @ is an alias to /src
import GestionCookies from '@/components/GestionCookies.vue'

export default {
  name: 'GestionCookiesView',
  components: {
    GestionCookies,
  }
}
</script>
