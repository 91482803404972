<template>

    <CGL msg="" displayTitle="show" /> 

</template>

<script>
// @ is an alias to /src
import CGL from '@/components/CGL.vue'

export default {
  name: 'CGLView',
  components: {
    CGL,
  }
}
</script>
