<template>
  <div class="gr-main-container">
    <section class="mentions">
      <div class="uk-container">
        <h2>Mentions legales</h2>
        <h3>Informations Editeur</h3>
        <p>SOCIETE REUNIONNAISE DU RADIOTELEPHONE – SRR<br/>Siège social : 21 rue Pierre Aubert, ZI du Chaudron, 97490 Sainte Clotilde</p>
        <p>Société en commandite simple <span>de 3 375 165 €</span><br/>393 551 007 RCS Saint-Denis</p>
        <h3>Informations complémentaires</h3>
        <p>Siret: 39355100700031 <br/>APE : 6120Z Télécommunications sans fil</p>
        <h3>Informations Hébergeur</h3>
        <p>SOCIETE REUNIONNAISE DU RADIOTELEPHONE – SRR<br/>Siège social : 21 rue Pierre Aubert CS 62001 97743 Sainte Denis Cedex 9</p>
        <p>Société en commandite simple <span>de 3 375 165 €</span><br/>393 551 007 RCS Saint-Denis</p>
        <h3>Informations complémentaires </h3>
        <p>Siret: 39355100700031 <br/>APE : 6120Z Télécommunications sans fil</p>
        <h3>Directeur de la publication</h3>
        <p>Mathieu COCQ, representant permanent du gérant, La SRR</p>
        <h3>Conditions d’utilisation du site</h3>
        <h4>Définitions</h4>
        <p>Il est convenu d’attribuer mots commençant par une majuscule, qu’ils soient au pluriel ou au singulier, la définition suivante:</p>
        <p><span>– Adresse IP :</span> Nombre qui est automatiquement assigné à l’ordinateur de l’internaute par son fournisseur d’accès Internet lorsqu’il navigue sur internet et qui permet de l’identifier.</p>
        <p><span>– Cookie (Témoin de connexion) :</span> Petit fichier stocké après acceptation de l’internaute sur le disque dur de son ordinateur. Ce fichier permet à un site web d enregistrer des informations relatives à la navigation de l internaute sur les pages de ce site et de faciliter l utilisation ultérieure du site web par le même internaute.</p>
        <p><span>– Site :</span> Tout site internet appartenant ou exploités par une société faisant partie du groupe SFR. Il s’agit notamment des sites sfr.re, sfr.yt, sfrbusiness.re.</p>
        <p><span>– Service :</span> Ensemble des services proposés sur le Site à tout internaute qu’il soit ou non abonné à une quelconque offre SRR.</p>
        <h4>Accès au Site</h4>
        <p>L’accès au Site est réservé à un usage personnel et professionnel.</p>
        <p>SFR se réserve le droit de suspendre, d’interrompre ou de limiter sans avis préalable l’accès à tout ou partie du Site.</p>
        <p>Les conditions d’utilisation du Site ainsi que les mentions légales sont susceptibles, d’être modifiées à tout moment et sans préavis par SFR.</p>
        <h4>Utilisation du Site</h4>
        <p>En utilisant le Site, l’internaute s’engage à prendre connaissance des mentions légales, respecter les présentes conditions d’utilisation du Site et faire un usage personnel et professionnel du Site.</p>
        <p>Sous réserve des dispositions spécifiques applicables aux données personnelles, toutes les informations transmises par l’internaute sur Internet, notamment via le Site, seront considérées comme non confidentielles. L’internaute autorise SFR ou l’une quelconque de ses filiales à utiliser les informations qu’il aura ainsi transmises.</p>
        <p>L’internet peut personnaliser sa page d’accueil à l’aide de contenus extérieurs au Site, en enregistrant ses paramètres de préférences dans un Cookie, cette personnalisation devra respecter les dispositions visées dans les présentes. Il pourra également supprimer le Cookie dans lequel ses préférences sont enregistrées selon la procédure détaillée dans la politique de gestion des cookies du Site web.</p>
        <p>Lorsque le Site permet à l’internaute d’envoyer et/ou de publier des contenus (textes, photographies, vidéos, etc.) de quelque nature que ce soit, l’internaute s’engage à ce que ces contenus respectent la législation applicable et ne portent ni atteinte aux droits des tiers, ni à l’ordre public et aux bonnes mœurs tels que définis par la législation française.</p>
        <p>En particulier, ces contenus ne doivent pas présenter de caractère pornographique ou pédophile, porter à la sécurité ou à l’intégrité d’un Etat ou d’un territoire, quel qu’il soit, inciter à la haine, à la violence, au suicide, au racisme, à l’antisémitisme, à la xénophobie, à l’homophobie, faire l’apologie des crimes de guerre ou des crimes contre l’humanité.</p>
        <p>La souscription et l’utilisation des Services font l’objet de conditions générales et de conditions particulières de vente et/ou d’utilisation spécifiques.</p>
        <h4>Liens vers d’autres sites</h4>
        <p>Il peut être proposé des liens depuis les Sites vers des sites tiers. Ces sites sont indépendants, SRR n’édite pas et ne contrôle pas ces sites. Les liens vers tous sites ne constituent, en aucun cas, une approbation ou un partenariat entre SRR et ces sites. Dès lors, SRR ne saurait être responsable de leurs contenus, leurs produits, leurs publicités ou tous éléments ou services présentés sur ces sites. Par ailleurs, ces sites sont soumis à leurs propres conditions d’utilisation.</p>
        <p>Les autres produits ou noms mentionnés sur ce Site peuvent être des marques déposées ou des noms de marque déposés par des tiers. L’accès aux Sites par l’utilisateur ne saurait être interprété comme lui conférant, de manière implicite ou autrement, le(a) moindre licence ou autorisation sur les marques qui y apparaissent sans l’accord préalable écrit du tiers qui en est détenteur.</p>
        <h3>Données personnelles</h3>
        <p>Dans le cadre du Règlement Général sur la Protection des Données (RGPD), vous disposez de droits concernant l’utilisation de vos Données à Caractère Personnel (DCP) par SFR.<br/>
SFR traite les DCP dans le respect des dispositions de la loi n°78-17 du 6 janvier 1978 modifiée relative à l’informatique, aux fichiers et aux libertés et de la réglementation européenne en vigueur.</p>
        <p>Le traitement des DCP a pour finalités : la gestion du compte du Client y compris la prévention des impayés et la lutte contre la fraude, le développement de produits et services SFR ou de tiers, la réalisation d’études statistiques, d’analyses et de mesures d’audience, l’enrichissement et la valorisation de la base clients SFR, la fourniture de contenus localisés et de recommandations personnalisées, la communication avec le Client à propos des offres et services de SFR (par e-mail, messages instantanées, SMS et via des services de messagerie en ligne) et dans le cadre de l’assistance technique, le cas échéant, après recueil du consentement préalable du Client conformément à la Réglementation. La fourniture de certaines DCP par le Client conditionne la conclusion et, dans certains cas, l’exécution du contrat par SFR.</p>
        <p>A tout moment, vous pouvez demander l’accès aux DCP vous concernant, la rectification ou l’effacement de celles-ci (dans la mesure où cela n’empêche pas la bonne exécution du contrat ou le respect des obligations légales de SFR) et la limitation d’un ou plusieurs traitements particuliers vous concernant, dans les conditions prévues par la Réglementation. Vous pouvez également vous opposer à un traitement des DCP et vous avez un droit à leur portabilité, dans les conditions fixées par la Réglementation. De manière générale, vous avez le droit de comprendre et d’interroger SFR à propos de l’utilisation qui est faire de vos DCP.<br/>
Nous vous informons que vous disposez du droit de donner instruction de communiquer ou non vos données à un tiers que vous aurez préalablement désigné.</p>
        <p>Pour cela, vous pouvez envoyer un courrier postal à  :<br/>
SRR – Service Client, DPO – Délégué à la Protection des Données – 21 rue Pierre Aubert CS 62001 – 97743 Saint-Denis Cedex.<br/>
Ce courrier devra être accompagné de votre nom, prénom, numéro d’appel ainsi que d’une copie de votre pièce d’identité.</p>
        <p><a href="https://www.sfr.re/sites/default/files/documents/RGPD/FormulaireDonn%C3%A9esPersonelles.pdf" target="_blank">Cliquez ici pour télécharger le formulaire de demande d’accès aux données personnelles.</a></p>
        <p>Vous pouvez aussi exercer vos droits par voie électronique à l’adresse : donneespersonnelles@srr.fr</p>
        <p>Ce courriel devra être accompagné de votre nom, prénom, numéro d’appel ainsi que d’une copie de votre pièce d’identité.</p>
        <p>Si vous n’êtes pas satisfait de nos échanges, vous avez la possibilité de saisir la Commission Nationale de l’Informatique et des Libertés (CNIL) à l’adresse suivante : CNIL – 3 places de Fontenoy – TSA 80715 – 75334 Paris Cedex 07.</p>
        <p><a href="https://www.sfr.re/sites/default/files/documents/RGPD/Politique%20protection%20DP_20182205_VERSIONFINALE_.pdf" target="_blank">Cliquez ici pour télécharger la politique de protection des données à caractère personnel</a>.</p>
        <h3>Cookies</h3>
        <p>Lors de la première navigation sur le Site, l’internaute est amené à donner son consentement préalable (par le biais d’un bandeau apparaissant sur son écran) à l’implantation de Cookies sur le disque dur de son ordinateur. Ces Cookies ont pour objet d’enregistrer les informations relatives à la navigation sur le Site (date et heure de la consultation, page consultée, date et heure du clic, lieu du clic…). Ces informations permettent à SRR de personnaliser le Site et de faciliter l’accès aux rubriques. En effet, SRR peut traiter les informations personnelles de l’internaute en vue de personnaliser les contenus publicitaires qui lui sont proposés, lui faire part d’offres promotionnelles ou l’informer sur de nouvelles offres.</p>
        <p>Les cookies sont conservés pour une durée maximale de treize (13) mois à compter de leur dépôt sur votre terminal. A l’expiration de ce délai, votre consentement sera à nouveau requis.</p>
        <p>L’internaute peut s’opposer à l’enregistrement de Cookies en modifiant la configuration du navigateur de son ordinateur de la manière suivante:</p>
        <p>Pour Microsoft Internet Explorer :<br/>
1. Choisir le menu ” Outils ” (ou ” Tools “), puis ” Options Internet ” (ou ” Internet Options “)<br/>
2. Cliquer sur l’onglet ” Confidentialité ” (ou ” Confidentiality “)<br/>
3. Sélectionner le niveau souhaité à l’aide du curseur</p>
        <p>Pour Mozilla Firefox :<br/>
1. Choisir le menu ” Outils ” > ” Options “<br/>
2. Cliquer sur l’option ” Vie Privée “<br/>
3. Rubrique ” Cookies “</p>
        <p>Pour Chrome :<br/>
1. Choisir le menu ” Edition ” > ” Préférences “<br/>
2. Cliquer sur l’option ” Données personnelles “<br/>
3. Rubrique ” Cookies “</p>
        <p>Pour Safari :<br/>
1. Choisir le menu ” Edition ” > ” Préférences “<br/>
2. Cliquer sur l’option ” Données personnelles “<br/>
3. Rubrique ” Cookies “</p>
        <h3>Propriété de SFR</h3>
        <p>L’ensemble des contenus (photos, textes, vidéo, son, etc.) consultables sur le Site et/ou via les Services sont et demeurent la propriété exclusive de SRR et/ou de ses partenaires. L’internaute n’est donc pas habilité à reproduire, modifier, représenter, transférer ou commercialiser ces contenus  sous quelque forme que ce soit ou par quelque procédé que ce soit sans l’autorisation préalable écrite de SRR</p>
        <p>En toute hypothèse, l’internaute ne peut réaliser aucune utilisation secondaire des contenus du Site à titre onéreux ou gratuit. Pour toute réalisation d’un lien avec le Site, l’internaute devra contacter au préalable SRR à l’adresse suivante afin d’obtenir l’autorisation nécessaire à cet effet : SRR- Direction Marketing -21 Rue Pierre Aubert – CS 62001 – 97743 Saint-Denis Cedex 9.</p>
        <p>Le non-respect de cette interdiction peut notamment constituer une contrefaçon des droits de propriété intellectuelle ou une atteinte aux droits des personnes et peut à ce titre engager la responsabilité de l’internaute, y compris dans le cadre d’une action pénale.</p>
        <h3>Loi applicable</h3>
        <p>Le Site est régi par la loi française.</p>
        <p>Les présentes conditions générales d’utilisation et mentions légales sont soumises au droit français. En cas de différend et à défaut d’accord amiable, le litige sera porté devant les tribunaux français conformément aux règles de compétence en vigueur.</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'MentionsLegales',
  props: {
    msg: String
  }
}
</script>
