import { createApp } from 'vue'
import VueMatomo from 'vue-matomo'
import App from './App.vue'
import router from './router'

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
UIkit.use(Icons);

export default {
  name: 'App',
  data: () => ({
  }),
  methods: {
  }
};
import 'uikit/dist/css/uikit.min.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import './assets/css/suivilive.css';
import './assets/js/main.js';
import store from './store' 

//createApp(App).use(store).use(router).use(VueMatomo,{host: '//ma.sfrhd.net/matomo',siteId: 1,trackerFileName:'matomo',router: router,enableLinkTracking: true,cookieDomain:'*.grandraid.sfr.re',domains:'*.grandraid.sfr.re',trackInitialView: true,debug: false,}).mount('#app')
createApp(App).use(store).use(router).use(VueMatomo,{host: '//mgr.sfrhd.net/',siteId: 1,trackerFileName:'matomo',router: router,enableLinkTracking: true,cookieDomain:'*.grandraid.sfr.re',domains:'*.grandraid.sfr.re',trackInitialView: true,debug: false,}).mount('#app')
//createApp(App).use(store).use(router).mount('#app')
